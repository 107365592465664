//var App = angular.module('App', []);

jQuery(document).ready(function() {
    'use strict';
    jQuery('.resource-download-item').matchHeight();
    jQuery('.resource-download-name').matchHeight();
    jQuery('.resource-download-thumbnail').matchHeight();
    jQuery('.resource-download-description').matchHeight();
});


